body {
  background-color: black;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
}

.App {
  display: flex;
  justify-content: center;
}

.container {
  padding: 10px;
  width: 800px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.title {
  margin-bottom: 25px;
}

.description {
  margin-top: 0;
}

.blue {
  color: #61EFFF;
}


.button {
  width: 300px;
  height: 100px;
}

.interview {
  font-size: 25px;
}

.question {
  color: white;
  text-align: left;
}

.again-button {
  margin-top: 20px;
}